import React from "react"
import Anchor from "../../core/components/anchor"
import Banner from "../../client/compositions/banner"
import Block from "../../core/components/block"
import Button from "../../client/compositions/button"
import RelatedProducts from "../../client/compositions/related-products"
import Decoration from "../../client/compositions/decoration"
import DecorationFluid from "../../client/compositions/decoration-fluid"
import Navigation from "../../core/components/navigation"
import Overlap from "../../client/compositions/overlap"
import Row from "../../core/components/row"
import Slider from "../../core/components/slider"
import { PortfolioTaglineMenu } from "../../client/compositions/tagline"
import { quickMenu } from "../../client/elements/menus"

/**
 * Project: Breakers Page
 * @see https://zpl.io/bzgYOWl
 */
function ProjectBreakersPage() {
  return (
    <article
      id="page-portfolio-breakers-resort"
      className="page-content portfolio-page light-quick-menu"
    >
      {quickMenu}

      <Banner
        title={
          <>
            <sup aria-hidden={true} role="presentation">
              WineTrend Custom Project
            </sup>
            <div>The Breakers Resort</div>
          </>
        }
        content={<>Palm Beach, FL</>}
        className="flipped portfolio-banner"
      >
        <DecorationFluid image="portfolio-breakers-resort/banner.jpg" />
      </Banner>

      <Row id="summary" className="content-plus-media flipped">
        <Block className="block-content dropped">
          <div className="content">
            <dl className="details">
              <div className="detail">
                <dt className="title">General Contractor</dt>
                <dd className="content">Weitz Construction (Florida)</dd>
              </div>
              <div className="detail">
                <dt className="title">Designer</dt>
                <dd className="content">Tihany Design (Manhattan, NY)</dd>
              </div>
              <div className="detail">
                <dt className="title">Bottle capacity</dt>
                <dd className="content">3000</dd>
              </div>
            </dl>
            <p>
              A 30' long wine partition forms the jewel of this historic
              restaurant, bringing to life the vision of renowned interior
              designer, Adam Tihany.
            </p>
          </div>
        </Block>
        <Block className="block-media">
          <DecorationFluid image="/portfolio-breakers-resort/content-01.medium.jpg" />
        </Block>
      </Row>

      <Row id="gallery-one" className="media-plus-media">
        <Block className="block-media">
          <DecorationFluid image="/portfolio-breakers-resort/content-02.medium.jpg" />
        </Block>
        <Block className="block-media">
          <DecorationFluid image="/portfolio-breakers-resort/content-03.medium.jpg" />
        </Block>
      </Row>

      <Row id="features" className="content-plus-media">
        <Block className="block-content dropped">
          <h4 className="title">Features</h4>
          <div className="content">
            <ul className="features">
              <li className="feature">
                Number 8 mirror finish stainless-steel glassed-in wine-wall.
              </li>
              <li className="feature">
                30-foot long free-standing conditioned dual zoned temperature.
              </li>
              <li className="feature">
                Brushed stainless steel floor-to-ceiling Inspire collection
                racks with custom angled presentation to accommodate all types
                of wine.
              </li>
              <li className="feature">
                Used as a partition between the restaurant and a private dining
                room.
              </li>
            </ul>
          </div>
        </Block>
        <Block className="block-media">
          <DecorationFluid image="/portfolio-breakers-resort/content-04.medium.jpg" />
        </Block>
      </Row>

      <Row id="gallery-two" className="single-media">
        <Block className="block-media">
          <Decoration image="/portfolio-breakers-resort/content-05.jpg" />
        </Block>
      </Row>

      <RelatedProducts
        id="related-products"
        products={[
          {
            slug: "inspire-wall-mounted",
            title: "Inspire Collection",
            url: "products/inspire/wall-mounted/",
          },
        ]}
      />

      <PortfolioTaglineMenu portfolioSlug="portfolio-breakers-resort" />
    </article>
  )
}

export default ProjectBreakersPage
